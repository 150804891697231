
import { defineComponent } from 'vue';
import { useI18n } from '@/plugins/i18n';

import TopTemplate from '@/components/templates/TopTemplate.vue';

import JaMessages from '@/assets/i18n/ja/top.json';
import EnMessages from '@/assets/i18n/en/top.json';
import { pageview } from 'vue-gtag';

export default defineComponent({
  name: 'Top',
  components: { TopTemplate },
  methods: {
    track() {
      pageview({ page_path: '/' });
    },
  },
  setup() {
    const i18n = useI18n();
    i18n.$setMessages(JaMessages, EnMessages);
    return {
      i18n,
    };
  },
});
