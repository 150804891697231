
import { defineComponent } from 'vue';
import TheDetailPageHeader from '@/components/organisms/detailPage/TheDetailPageHeader.vue';
import TheDetailPageBody from '@/components/organisms/detailPage/TheDetailPageBody.vue';
import ProgramBody from '@/components/organisms/program/ProgramBody.vue';
// import LinkButton from '@/components/organisms/utils/LinkButton.vue';
import { useI18n } from '@/plugins/i18n';

export default defineComponent({
  name: 'ProgramTemplate',
  components: {
    TheDetailPageHeader,
    TheDetailPageBody,
    ProgramBody,
  },
  props: {
    i18n: {
      type: Object,
    },
  },
  setup() {
    const i18n = useI18n();
    const locale = i18n.locale;
    return {
      locale,
    };
  },
});
