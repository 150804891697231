import { RouteRecordRaw } from 'vue-router';
import General from '@/views/General.vue';
import Terms from '@/components/pages/terms/Terms.vue';
import PrivacyPolicy from '@/components/pages/terms/PrivacyPolicy.vue';

const TERMS_ROUTES: Array<RouteRecordRaw> = [
  {
    path: '/terms',
    alias: '/en/terms',
    name: 'TermsRoot',
    component: General,
    children: [
      {
        path: '',
        name: 'Terms',
        component: Terms,
        meta: { title: '参加規約', enTitle: 'Policies' },
      },
      {
        path: 'privacy_policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
        meta: {
          title: '個人情報の取り扱いについて',
          enTitle: 'Privacy Policy',
        },
      },
    ],
  },
];

export default TERMS_ROUTES;
