import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InstancesTemplate = _resolveComponent("InstancesTemplate")!

  return (_openBlock(), _createBlock(_component_InstancesTemplate, {
    i18n: _ctx.i18n,
    "event-list": _ctx.eventList,
    "permanent-instance-list": _ctx.permanentInstanceList,
    "neos-list": _ctx.neosList
  }, null, 8, ["i18n", "event-list", "permanent-instance-list", "neos-list"]))
}