import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-27a41d4c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "instance-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Heading = _resolveComponent("Heading")!
  const _component_InstanceListItem = _resolveComponent("InstanceListItem")!

  return (
      _ctx.instanceList[0] && _ctx.instanceList[0].isVisible && _ctx.instanceList[0].isOnTime
    )
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.instanceList[0])
          ? (_openBlock(), _createBlock(_component_Heading, {
              key: 0,
              tag: "h2"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getEventName(_ctx.instanceList[0].eventId)), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createElementVNode("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.instanceList, (instance) => {
            return (_openBlock(), _createBlock(_component_InstanceListItem, {
              key: instance.uid,
              "instance-name": 
          _ctx.getInstanceName(instance.type, instance.uid, instance.eventId)
        ,
              "max-user": instance.maxUser,
              "current-user": instance.currentUser,
              "is-full": instance.isFull,
              "is-visible": instance.isVisible,
              "instance-type": instance.type,
              "instance-page-url": instance.url,
              "show-join-button": _ctx.showJoinButton
            }, null, 8, ["instance-name", "max-user", "current-user", "is-full", "is-visible", "instance-type", "instance-page-url", "show-join-button"]))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}