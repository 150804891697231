
import { defineComponent } from 'vue';
import { provideI18n } from '@/plugins/i18n';
import { getUserLocale } from '@/libs/utils/locale';

export default defineComponent({
  name: 'App',
  setup() {
    provideI18n({
      locale: getUserLocale(),
      messages: {},
    });
  },
});
