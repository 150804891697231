
import { defineComponent, ref } from 'vue';
import TheDetailPageBody from '@/components/organisms/detailPage/TheDetailPageBody.vue';
import ProgramCard from '@/components/organisms/program/ProgramCard.vue';
import DialogBox from '@/components/molecules/DialogBox.vue';

export default defineComponent({
  name: 'ProgramTemplate',
  components: {
    TheDetailPageBody,
    ProgramCard,
    DialogBox,
  },
  data() {
    return {
      dialogTimetable: false,
      dialogMap: false,
      eventData: [
        {
          id: 'avatar_dressing_room',
          title: 'アバター試着室',
          date: '常設企画',
          image: ref().value = require('@/assets/images/program/program-avatar.jpg'),
          platform: ['VRChat', 'NeosVR'],
          place: 'アバター試着室',
          description:
            'アバター試着室では、皆さんから事前に募集したいろいろな種族のケモノアバターを体験することができます。きっとあなたのお気に入りのアバターも見つかるはず！ ぜひ体験しに来てくださいね♪',
          access:
            'VR JMoFワールドの「アバター試着室」で各種アバターを体験できます。',
        },
        {
          id: 'poster_exhibition',
          title: 'ポスター展示',
          date: '常設企画',
          image: ref().value = require('@/assets/images/program/program-poster.jpg'),
          platform: ['VRChat', 'NeosVR'],
          place: 'ホワイエ',
          description:
            'ケモノやVRに関するポスターを、VR JMoFワールドに一挙展示します！ いろいろなイベントやケモノサークルを知ることができるかも……！？ 新しいジャンル・情報を知りたい方は要チェック！',
          access: 'VR JMoFワールドの「ホワイエ」で展示をご覧になれます。',
        },
        {
          id: 'opening',
          title: '開会式',
          date: '11:30-11:45 JST',
          image: ref().value = require('@/assets/images/program/program-opening.jpg'),
          platform: ['VRChat', 'NeosVR'],
          place: '動画配信（ホール・ロビー／中庭）',
          description:
            'VR JMoF Prologue始まるよ！！！ 実施概要、参加にあたっての注意事項、企画の内容などを説明します！ ここから参加してお祭り気分になっちゃおう！！',
          access:
            'VR JMoFワールドの「ホール・ロビー」と「中庭」の配信画面、または公式動画配信から、開会式の様子を視聴できます。',
        },
        {
          id: 'beginners_tour',
          title: 'VRChat 初心者ツアー',
          date: '12:00-13:00 JST',
          image: ref().value = require('@/assets/images/program/program-tour.jpg'),
          platform: ['VRChat'],
          place: 'ホール・ロビー',
          description:
            'VRChatを始めたばかりの方や慣れていない方を対象に、VRChatの世界を楽しむためのコツや方法をお伝えします。前半は初心者講座、後半はオススメのワールドのツアーをします。きれいなワールド巡りや楽しい体験を通じて、この世界の魅力を一緒に知っていきましょう！',
          access:
            '企画開始時刻の10分前から企画開始時刻までの間に、「<a href="/access/instances">インスタンス一覧</a>」ページから「VRChat初心者ツアー」のインスタンスにJoin（入場）し、「ホール・ロビー」までお越しください。',
          attention: [
            'ワールド入場人数に限りがありますので、VRChatに慣れている方は参加をご遠慮ください。',
            'Note: This program will be held in the Japanese language only.',
          ],
        },
        {
          id: 'avatar_setup',
          title: 'アバターおまかせセットアップ',
          date: '12:00-13:30 JST',
          image: ref().value = require('@/assets/images/program/program-neossetup.jpg'),
          platform: ['NeosVR'],
          place: '',
          description:
            'NeosVRでも遊びたいけどアバターセットアップは面倒くさい…そんな人に向けたアバターセットアップイベント！<br />操作はドラッグ&ドロップだけ！あとはNeosVRのプロにアバターセットアップを任せちゃおう！',
          access:
            '以下の事前登録フォームより、参加登録をお願いします。 参加登録された方に、事前に参加方法をご案内します。<div class="program-entry"><a href="https://forms.gle/7rxJoyi6kLVNCAS88" target="_blank">事前登録フォーム</a></div><br />当日参加される方は、「<a href="/access/instances">インスタンス一覧</a>」ページから「NeosVR メイン会場」のセッションにJoinし、運営スタッフにお声掛けください。',
          attention: [
            'ご案内できる人数には限りがありますので、事前登録を推奨します。',
            '参加する方はFBXやGLB等のアバターモデルファイルおよびテクスチャデータを事前にご用意ください。',
            'Note: This program will be held in the Japanese language only.',
          ],
        },
        {
          id: 'fetish_talk',
          title: 'フェチトーーク！！in VR',
          date: '13:30-14:30 JST',
          image: ref().value = require('@/assets/images/program/program-talk.jpg'),
          platform: ['VRChat'],
          place: '中庭',
          description:
            'あんな性癖やこんなシチュについて語りたい……。でもなかなかそんな機会ないし、オフで会ってもみんなFG●の話ばかりしてる……。<br />そんなあなたに、フェチトーーク！！<br />性癖・フェティシズムに特化した煩悩・妄想・欲望まみれのトーク企画です。<br />今回のテーマは「イヌになりた～～～い！（イヌTF）」。 吠えたい、撫でられたい、飼われたい、給餌されたい、首輪着けられたい、とにかくイヌになりたい人は集まれ！わんわん！',
          access:
            '企画開始時刻までに、公式動画配信にて、開催されるインスタンスの告知を行います。参加を希望される方は、「<a href="/access/instances">インスタンス一覧</a>」ページから告知されたインスタンスにJoinし、「中庭」までお越しください。',
        },
        {
          id: 'group_photo',
          title: '集合写真',
          date: '14:45 JST (Shooting Time)',
          image: ref().value = require('@/assets/images/program/program-photo.jpg'),
          platform: ['VRChat'],
          place: '中庭',
          description:
            'ケモノイベントの目玉ともいえる集合写真、今回も中庭で撮影します。シャッター時刻に遅れないように集合だ！<br />（シャッター時刻 14:45）',
          access:
            'VRChatでいずれかの公式インスタンスにJoinして「中庭」に集合し、シャッター時刻までにカメラの設置および整列を完了させ、シャッター時刻になったら各自で撮影を行なってください。<br />当日は公式動画配信でシャッター時刻などのご案内をしますので、参加の際は中庭の配信画面をご覧ください。',
          attention: ['スタッフによる公式撮影は行いません。'],
        },
        {
          id: 'jmof_rock_festival',
          title: 'JMoF Rock Festival 2023 - 出演者座談会 & ミニライブ',
          date: '15:00-16:30 JST',
          image: ref().value = require('@/assets/images/program/program-rock.jpg'),
          platform: ['VRChat', 'NeosVR'],
          place: '動画配信（ホール・ロビー／中庭）',
          description:
            'JMoF Rock Festival 2023 の出演者が集合して、これまでの、そしてこれからのロックフェスについて、思いを熱く語り合います。最後にスペシャルバンドによるミニライブも！',
          access:
            'VR JMoFワールドの「ホール・ロビー」と「中庭」の配信画面、または公式動画配信から、企画の様子を視聴できます。',
        },
        {
          id: 'neostart',
          title: 'NeoStart!',
          date: '16:30-18:00 JST',
          image: ref().value = require('@/assets/images/program/program-neostart.jpg'),
          platform: ['NeosVR'],
          place: '',
          description:
            'NeoStart! は、初めての方でもNeosVRの楽しさを知ることができる体験型イベントです。その場でアバターにアクセサリーをつけて楽しんだり、最新のガジェットで遊んだりして、ケモノ好きのみなさんに最適な最先端のメタバースプラットフォームを体感できます。あなたもNeosVRでもう一つのケモノライフを体験してみませんか？<br />参加者限定で、VRJMoF参加証に付けられる特別参加記念リボンも用意しています！<br />当日は案内人が丁寧に説明しながら皆さんをご案内しますので、ぜひお気軽にご参加ください！<br />最後にはみんなで集合写真を撮影するよ！',
          access:
            '以下の事前登録フォームより、参加登録をお願いします。参加登録された方に、事前に参加方法をご案内します。<div class="program-entry"><a href="https://forms.gle/7rxJoyi6kLVNCAS88" target="_blank">事前登録フォーム</a></div><br />当日参加される方は、「<a href="/access/instances">インスタンス一覧</a>」ページから「NeosVR メイン会場」のセッションにJoinし、運営スタッフにお声掛けください。',
          attention: [
            'ご案内できる人数には限りがありますので、事前登録を推奨します。',
            '本企画は「NeosVR未プレイの方」「インストールはしたけれどほとんど遊んでいない初心者の方」を対象としています。',
            '友人と一緒にご案内することもできます。フォームにその旨をご記載ください。',
            'Note: This program will be held in the Japanese language only.',
          ],
        },
        {
          id: 'what_is_jmof',
          title: 'JMoFってなんだろう？',
          date: '18:00-19:00 JST',
          image: ref().value = require('@/assets/images/program/program-about.jpg'),
          platform: ['VRChat', 'NeosVR'],
          place: '動画配信（ホール・ロビー／中庭）',
          description:
            'VR JMoFには参加したことがあるけれど、実際に豊橋で開催されるJMoFってどんなイベントなんだろう？という方に向けて、リアルJMoFの楽しみ方を教えちゃいます！<br />興味を持ってくれた人は<a href="https://www.jmof.jp/" target="_blank">JMoF公式ウェブサイト</a>も要チェックだ！',
          access:
            'VR JMoFワールドの「ホール・ロビー」と「中庭」の配信画面、または公式動画配信から、企画の様子を視聴できます。',
        },
        {
          id: 'closing',
          title: '閉会式',
          date: '19:00-19:15 JST',
          image: ref().value = require('@/assets/images/program/program-ending.jpg'),
          platform: ['VRChat', 'NeosVR'],
          place: '動画配信（ホール・ロビー／中庭）',
          description: '',
          access:
            'VR JMoFワールドの「ホール・ロビー」と「中庭」の配信画面、または公式動画配信から、企画の様子を視聴できます。',
        },
        {
          id: 'dead_dog_party',
          title: 'デッドドッグパーティー',
          date: '20:30 JST -',
          image: ref().value = require('@/assets/images/program/program-ddp.jpg'),
          platform: ['VRChat', 'NeosVR'],
          place: 'どこでも',
          description:
            'えっ！！！ VR JMoF終わってないじゃん！！？！？ そう、デッドドッグパーティーが残っている……。いうなれば公式2次会です。なんとVRならみんな安全にお酒が飲めるッ！！ 羽目を外しても自己責任！！ 末法の世の終わらない飲み会へようこそ！！！！！！',
          access:
            '閉会式後に一度休憩して、オフロに入って、酒とツマミを用意して、その画像を「#VR_MELTDOWN」のハッシュタグをつけてTwitterにアップロードして、もう一度VR JMoFにJoinだ！！！',
          attention: ['閉会後のため、スタッフによる会場の管理は行いません。'],
        },
      ],
    };
  },
});
