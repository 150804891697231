
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SignalText',
  props: {
    signal: {
      type: String,
      require: true,
      validator: function(value: string) {
        return ['danger', 'warning', 'caution', 'note'].indexOf(value) !== -1;
      },
    },
  },
});
