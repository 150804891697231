
import { defineComponent } from 'vue';
import { useI18n } from '@/plugins/i18n';

export default defineComponent({
  name: 'TopNews',
  setup() {
    const i18n = useI18n();
    const locale = i18n.locale;
    const newsList = [
      {
        type: 'all',
        title_ja: '2024年1月3日',
        title_en: 'Jan. 3rd, 2024',
        body_ja:
          '<a href="/program">VR JMoF 2024</a>の開催情報を掲載しました！',
        body_en:
          '<a href="/program">The VR JMoF 2024</a> Information has been published!',
      },
      {
        type: 'all',
        title_ja: '2023年11月12日',
        title_en: 'Nov. 12th, 2023',
        body_ja:
          '<a href="/2024-prologue">VR JMoF Prologue 2024</a> の情報を公開しました！',
        body_en:
          '<a href="/2024-prologue">VR JMoF Prologue 2024</a> Information has been published!',
      },
      {
        type: 'all',
        title_ja: '2023年1月4日',
        title_en: 'Jan. 4th, 2023',
        body_ja:
          '<a href="/program">企画紹介</a>を公開しました！<br />VR JMoF「参加証」風オリジナルFBXモデルの配布を開始しました！',
        body_en:
          '<a href="/program">Program</a> has been published!<br />We have started distribution the VR JMoF “con-badge”-like original FBX model!',
      },
      {
        type: 'all',
        title_ja: '2022年11月28日',
        title_en: 'Nov. 28th, 2022',
        body_ja:
          'VR JMoF 2023の開催情報を掲載しました！<br /><a href="/program/apply_avatar_poster">アバター&ポスター募集</a>を開始しました！',
        body_en:
          'The VR JMoF 2023 Information has been published!<br /><a href="/program/apply_avatar_poster">Avatar & Poster Submissions</a> are now open!',
      },
      {
        type: 'all',
        title_ja: '2022年10月10日',
        title_en: 'Oct. 10th, 2022',
        body_ja:
          '<a href="/prologue">VR JMoF Prologue</a>の開催情報を掲載しました！',
        body_en:
          '<a href="/prologue">The VR JMoF Prologue Information</a> has been published!',
      },
    ];
    return {
      locale,
      newsList,
    };
  },
});
