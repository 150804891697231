import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9c93ec94"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "instance-box"
}
const _hoisted_2 = { class: "instance-inner" }
const _hoisted_3 = { class: "instance-count" }
const _hoisted_4 = { class: "count-visitors" }
const _hoisted_5 = { class: "count-total" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = ["href"]
const _hoisted_8 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isVisible === 1)
    ? (_openBlock(), _createElementBlock("li", _hoisted_1, [
        _createElementVNode("h2", {
          class: _normalizeClass(
        `instance-name ${
          _ctx.instanceType !== _ctx.INSTANCE_TYPE.PERMANENT ? 'state-event' : ''
        }`
      )
        }, _toDisplayString(_ctx.$props.instanceName), 3),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$props.currentUser), 1),
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$props.maxUser), 1)
          ]),
          (_ctx.showJoinButton)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                (_ctx.$props.isFull === 0)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 0,
                      class: "instance-button",
                      href: _ctx.$props.instancePageUrl,
                      target: "_blank"
                    }, _toDisplayString(_ctx.locale === 'ja' ? '入場する' : 'Join'), 9, _hoisted_7))
                  : (_openBlock(), _createElementBlock("a", {
                      key: 1,
                      class: "instance-button state-full",
                      href: _ctx.$props.instancePageUrl,
                      target: "_blank"
                    }, _toDisplayString(_ctx.locale === 'ja' ? '満員' : 'Full'), 9, _hoisted_8))
              ]))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}