export const EVENT_NAME: {
  [k: string]: {
    [k: string]: string;
  };
} = {
  opening: {
    ja: '開会式',
    en: 'Opening Ceremony',
  },
  tour_01: {
    ja: 'VRChat初心者ツアー①',
    en: 'VRChat Beginner Tour #1',
  },
  tour_02: {
    ja: 'VRChat初心者ツアー②',
    en: 'VRChat Beginner Tour #2',
  },
  creator_01: {
    ja: 'クリエイターズトーク① ～VTuber～',
    en: 'Creators’ Talk #1 ～Virtual YouTuber～',
  },
  creator_02: {
    ja: 'クリエイターズトーク② ～アバター制作～',
    en: 'Creators’ Talk #2 ～Avatar Creation～',
  },
  creator_03: {
    ja: 'クリエイターズトーク③ ～イベント運営～',
    en: 'Creators’ Talk #3 ～Event Management～',
  },
  kemoclub01: {
    ja: 'VR JMoF × KEMO CLUB ～Day One～',
    en: 'VR JMoF × KEMO CLUB ～Day One～',
  },
  kemoclub02: {
    ja: 'VR JMoF × KEMO CLUB ～Day Two～',
    en: 'VR JMoF × KEMO CLUB ～Day Two～',
  },
  avtr_mod: {
    ja: 'アバター改変講座',
    en: 'How to Modify Your Avatar',
  },
  closing: {
    ja: '閉会式',
    en: 'Closing Ceremony',
  },
  josetsu: {
    ja: '常設インスタンス',
    en: 'Permanent Instances',
  },
  beginner: {
    ja: 'VRChat初心者ツアー',
    en: 'VRChat Beginner Tour',
  },
  josetsu_test1011: {
    ja: '公式インスタンス(Test)',
    en: 'Official Instances',
  },
  beginner_test1011: {
    ja: 'VRChat初心者ツアー(Test)',
    en: 'VRChat Beginner Tour',
  },
  neos_josetsu: {
    ja: 'Resonite メイン会場',
    en: 'Resonite Main Sessions',
  },
  quest: {
    ja: 'Android(Quest)会場',
    en: 'Android(Quest) World',
  },
};
