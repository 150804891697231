
import { defineComponent, ref } from 'vue';

import TheDetailPageHeader from '@/components/organisms/detailPage/TheDetailPageHeader.vue';
import TheDetailPageBody from '@/components/organisms/detailPage/TheDetailPageBody.vue';
import Heading from '@/components/atoms/Heading.vue';
import SignalText from '@/components/atoms/SignalText.vue';
import { memberExpression } from '@babel/types';

export default defineComponent({
  name: 'FaqContactTemplate',
  components: {
    TheDetailPageHeader,
    TheDetailPageBody,
    Heading,
    SignalText,
  },
  data() {
    return {
      mainStaff: [
        {
          label_ja: '主催',
          label_en: 'Host Organization',
          member: [
            {
              name_ja: 'JMoF実行委員会',
              name_en: 'JMoF Executive Committee',
              icon: ref().value = require('@/assets/images/icons/jmof.png'),
              web: 'https://www.jmof.jp/',
            },
          ],
        },
        {
          label_ja: 'メインスタッフ',
          label_en: 'Main Staff',
          member: [
            {
              name_ja: 'Gentou',
              name_en: 'Gentou',
              icon: ref().value = require('@/assets/images/icons/gentou.jpg'),
              twitter: 'https://twitter.com/gen_tou',
            },
            {
              name_ja: 'すみた',
              name_en: 'Sumita',
              icon: ref().value = require('@/assets/images/icons/sumita.jpg'),
              twitter: 'https://twitter.com/alcoholic_wolf',
            },
            {
              name_ja: '黒鳥',
              name_en: 'kurotori',
              icon: ref().value = require('@/assets/images/icons/kurotori.png'),
              booth: 'https://kurotori.booth.pm/',
              twitter: 'https://twitter.com/kurotori4423',
            },
            {
              name_ja: 'いなぷ',
              name_en: 'Inapu',
              icon: ref().value = require('@/assets/images/icons/inapu.png'),
              twitter: 'https://twitter.com/reo_inoko',
            },
            {
              name_ja: 'いぇし',
              name_en: 'Yeshi',
              icon: ref().value = require('@/assets/images/icons/yeshi.jpg'),
              twitter: 'https://twitter.com/Yeshi_hobby',
            },
            {
              name_ja: 'うりいぬ',
              name_en: 'URII',
              icon: ref().value = require('@/assets/images/icons/uri_dog.jpg'),
              twitter: 'https://twitter.com/uriwinu',
            },
            {
              name_ja: 'あむ',
              name_en: 'Rabbuttz',
              icon: ref().value = require('@/assets/images/icons/rabbuttz.jpg'),
              twitter: 'https://twitter.com/Rabbuttz_VR',
            },
            {
              name_ja: '誰五味',
              name_en: 'Suigomi',
              icon: ref().value = require('@/assets/images/icons/suigomi.jpg'),
              booth: 'https://dareka53.booth.pm/',
              twitter: 'https://twitter.com/dareka53',
            },
            {
              name_ja: 'はい',
              name_en: 'hy',
              icon: ref().value = require('@/assets/images/icons/hy.jpg'),
              booth: 'https://hd4tails.booth.pm/',
              twitter: 'https://twitter.com/hd4tails',
            },
            {
              name_ja: 'ナボリスク',
              name_en: 'Naborisk',
              icon: ref().value = require('@/assets/images/icons/naborisk.png'),
              twitter: 'https://twitter.com/Naborisk1',
              web: 'https://naborisk.com',
            },
            {
              name_ja: '朧羽',
              name_en: 'oborobane',
              icon: ref().value = require('@/assets/images/icons/oborobane.jpg'),
              twitter: 'https://twitter.com/oboro_bane',
            },
            {
              name_ja: 'らいち',
              name_en: 'Lyche_e',
              icon: ref().value = require('@/assets/images/icons/lyche.png'),
              twitter: 'https://twitter.com/Lyche_e',
              booth: 'https://cyhele.booth.pm/',
            },
          ],
        },
      ],
      subStaff: [
        {
          label_ja: '会場',
          label_en: 'Venue',
          member: [
            {
              name_ja: 'ロワジールホテル豊橋',
              name_en: 'Loisir Hotel Toyohashi',
              icon: ref().value = require('@/assets/images/icons/loisirhoteltoyohashi.png'),
              web: 'https://www.loisir-toyohashi.com/',
            },
          ],
        },
        {
          label_ja: 'VRChat ワールド・アセット制作',
          label_en: 'VRChat World / Asset Creator',
          member: [
            {
              name_ja: 'ねこのいる工房（UNIUNI）',
              name_en: 'UNIUNI',
              icon: ref().value = require('@/assets/images/icons/uniuni.jpg'),
              booth: 'https://uniunishop.booth.pm/',
              twitter: 'https://twitter.com/lept_on',
            },
            {
              name_ja: '仮想狐のデザイン工房（るら）',
              name_en: 'VirtualFoxDesignStudio (Lura)',
              icon: ref().value = require('@/assets/images/icons/lura.jpg'),
              twitter: 'https://twitter.com/Lu_Ra_999',
            },
            {
              name_ja: 'けのも',
              name_en: 'Kenomo',
              icon: ref().value = require('@/assets/images/icons/kenomo.png'),
              twitter: 'https://twitter.com/Kenomo_Vworker',
              web: 'https://kenomo.jp/',
            },
            {
              name_ja: 'ロクロ',
              name_en: 'rocrocot',
              icon: ref().value = require('@/assets/images/icons/rocrocot.png'),
              twitter: 'https://twitter.com/rocrocot',
              web: 'https://rocrocot.myportfolio.com/work',
            },
          ],
        },
        {
          label_ja: 'Resonite チーム',
          label_en: 'Resonite Team',
          member: [
            {
              name_ja: 'うすたび',
              name_en: 'usutabi',
              icon: ref().value = require('@/assets/images/icons/usutabi.jpg'),
              twitter: 'https://twitter.com/usutabi',
            },
            {
              name_ja: 'オレンジ',
              name_en: 'Orange',
              icon: ref().value = require('@/assets/images/icons/orange.jpg'),
              booth: 'https://orange3134.booth.pm/',
              twitter: 'https://twitter.com/orange_3134',
              youtube: 'https://www.youtube.com/c/ORANGEchannelO',
            },
            {
              name_ja: '駒芝',
              name_en: 'Komashiba',
              icon: ref().value = require('@/assets/images/icons/komashiba.jpg'),
              booth: 'https://koma-lab.booth.pm/',
              twitter: 'https://twitter.com/komashiba33',
            },
            {
              name_ja: 'GONT_3',
              name_en: 'GONT_3',
              icon: ref().value = require('@/assets/images/icons/gont_3.jpg'),
              twitter: 'https://twitter.com/nvrttxb777',
            },
            {
              name_ja: 'すら',
              name_en: 'SLA',
              icon: ref().value = require('@/assets/images/icons/sla.jpg'),
              twitter: 'https://twitter.com/_SLAVR00_',
            },
            {
              name_ja: 'ととがんま',
              name_en: 'totegamma',
              icon: ref().value = require('@/assets/images/icons/totegamma.png'),
              twitter: 'https://twitter.com/totegamma',
              web: 'https://gammalab.net',
            },
            {
              name_ja: 'ねむのみ',
              name_en: 'Nemnomi',
              icon: ref().value = require('@/assets/images/icons/nemnomi.png'),
              twitter: 'https://twitter.com/pluser',
            },
            {
              name_ja: 'モトメイド',
              name_en: 'MotoMaid',
              icon: ref().value = require('@/assets/images/icons/motomaid.jpg'),
              twitter: 'https://twitter.com/moto_made',
            },
            {
              name_ja: 'れにうむ',
              name_en: 'rhenium',
              icon: ref().value = require('@/assets/images/icons/rhenium.jpg'),
              booth: 'https://r75.booth.pm/',
              twitter: 'https://twitter.com/rhenium_nv',
            },
            {
              name_ja: 'べるネ',
              name_en: 'berne',
              icon: ref().value = require('@/assets/images/icons/berne.png'),
              twitter: 'https://twitter.com/bernenoky',
            },
            {
              name_ja: '藤三郎',
              name_en: 'TOOMIRO',
              icon: ref().value = require('@/assets/images/icons/toomiro.png'),
              booth: 'https://toomiro.booth.pm/',
              twitter: 'https://twitter.com/TOOMIRO',
              skeb: 'https://skeb.jp/@TOOMIRO',
            },
            {
              name_ja: 'アルニラム',
              name_en: 'Alnilam',
              icon: ref().value = require('@/assets/images/icons/alnilam.jpg'),
            },
            {
              name_ja: 'ふわぶら',
              name_en: 'FuwattBrat',
              icon: ref().value = require('@/assets/images/icons/fuwattbrat.png'),
              twitter: 'https://twitter.com/fuwatt_brat',
            },
            {
              name_ja: 'あおいおるか',
              name_en: 'AoiOrca',
              icon: ref().value = require('@/assets/images/icons/aoi.png'),
              twitter: 'https://twitter.com/aoi_orca',
            },
            {
              name_ja: 'Riyu',
              name_en: 'Riyu',
              icon: ref().value = require('@/assets/images/icons/riyu.png'),
              twitter: 'https://twitter.com/RiyuVR',
            },
            {
              name_ja: 'きりゅう',
              name_en: 'Kiryu',
              icon: ref().value = require('@/assets/images/icons/kiryu.png'),
              twitter: 'https://twitter.com/ryugi_kiryu',
            },
            {
              name_ja: 'すず',
              name_en: 'suzu_dragon',
              icon: ref().value = require('@/assets/images/icons/suzu_dragon.png'),
              twitter: 'https://twitter.com/suzu_dragon',
            },
          ],
        },
        {
          label_ja: '過去のスタッフ・協力者',
          label_en: 'Our Friends Who Helped Us',
          member: [
            {
              name_ja: 'ぎがすけ商会（gigash）',
              name_en: 'Gigasuke Shokai (gigash)',
              icon: ref().value = require('@/assets/images/icons/gigash.jpg'),
              booth: 'https://gigasuke-shokai.booth.pm/',
              twitter: 'https://twitter.com/gigash',
            },
            {
              name_ja: 'ひるね小屋（睡犬）',
              name_en: 'Hirune House (suiken)',
              icon: ref().value = require('@/assets/images/icons/cwwdog.jpg'),
              booth: 'https://cwwdog.booth.pm/',
            },
            {
              name_ja: '株式会社みるくぽっと',
              name_en: 'Milkpot Co.,Ltd.',
              icon: ref().value = require('@/assets/images/icons/milkpot.png'),
            },
            {
              name_ja: '四つ足カルテット',
              name_en: 'quadruped quartet',
              icon: ref().value = require('@/assets/images/icons/quadruped.png'),
              booth: 'https://quadruped.booth.pm/',
            },
            {
              name_ja: 'Kemonap',
              name_en: 'Kemonap',
              icon: ref().value = require('@/assets/images/icons/kemonap.jpg'),
              booth: 'https://kemonap.booth.pm/',
              twitter: 'https://twitter.com/Kemonap_',
            },
            {
              name_ja: 'Anagura Works（やまかみ）',
              name_en: 'Anagura Works (Yamakami)',
              icon: ref().value = require('@/assets/images/icons/yamakami.jpg'),
              booth: 'https://anaguraworks.booth.pm',
              twitter: 'https://twitter.com/yamakami_gm',
            },
            {
              name_ja: 'KEMO CLUB',
              name_en: 'KEMO CLUB',
              icon: ref().value = require('@/assets/images/icons/kemoclub.png'),
            },
            {
              name_ja: 'nennnn',
              name_en: 'nennnn',
              icon: ref().value = require('@/assets/images/icons/nennnn.jpg'),
              twitter: 'https://twitter.com/nennnn4',
            },
            {
              name_ja: 'Giwa',
              name_en: 'Giwa',
              icon: ref().value = require('@/assets/images/icons/giwa.jpg'),
              twitter: 'https://twitter.com/G1wa_',
            },
          ],
        },
      ],
    };
  },
  props: {
    i18n: {
      type: Object,
    },
  },
});
