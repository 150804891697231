
import { defineComponent, ref } from 'vue';
import Heading from '@/components/atoms/Heading.vue';
import DialogBox from '@/components/molecules/DialogBox.vue';
import ProgramCard from '@/components/organisms/program/ProgramCard.vue';
import { useI18n } from '@/plugins/i18n';

export default defineComponent({
  name: 'ProgramBody',
  components: {
    Heading,
    DialogBox,
    ProgramCard,
  },
  props: {
    i18n: {
      type: Object,
    },
  },
  data() {
    return {
      dialogTimetable: false,
      dialogMap: false,
      eventData: [
        {
          id: 'avatar_dressing_room',
          image: ref().value = require('@/assets/images/program/program-avatar.jpg'),
          platform: ['VRChat'],
          date: '常設企画',
          date_en: 'Permanent Program',
          title: 'アバター試着室',
          title_en: 'Avatar Changing Room',
          description:
            'アバター試着室では、皆さんから事前に募集したいろいろな種族のケモノアバターを体験することができます。きっとあなたのお気に入りのアバターも見つかるはず！　ぜひ体験しに来てくださいね♪',
          description_en:
            "At the Avatar Changing Room, you can try out various furry avatars submitted by everyone before the event. We're sure you'll find an avatar that suits you best! Please come and try them on♪",
          access:
            'VR JMoFワールドの「アバター試着室」で各種アバターを体験できます。',
          access_en:
            'You can try out various avatars in the “Avatar Changing Room” in the VR JMoF world.',
        },
        {
          id: 'poster_exhibition',
          image: ref().value = require('@/assets/images/program/program-poster.jpg'),
          platform: ['VRChat', 'Resonite'],
          date: '常設企画',
          date_en: 'Permanent Program',
          title: 'ポスター展示',
          title_en: 'Poster Exhibition',
          description:
            'ケモノやVRに関するポスターを、VR JMoFワールドに一挙展示します！　いろいろなイベントやケモノサークルを知ることができるかも……！？　新しいジャンル・情報を知りたい方は要チェック！',
          description_en:
            "At the Avatar Changing Room, you can try out various furry avatars submitted by everyone before the event. We're sure you'll find an avatar that suits you best! Please come and try them on♪",
          access: 'VR JMoFワールドの「ホワイエ」で展示をご覧になれます。',
          access_en:
            'You can try out various avatars in the “Avatar Changing Room” in the VR JMoF world.',
        },
        {
          id: 'opening',
          image: ref().value = require('@/assets/images/program/program-opening.jpg'),
          platform: ['Streaming'],
          date: '1月5日（金） 17:00〜18:00',
          date_en: 'January 5th (Fri.) 17:00 - 18:00 JST',
          datelabel: 'day_1',
          title: '開会式',
          title_en: 'Opening Ceremony',
          description:
            'VR JMoF始まるよ！！！　実施概要、参加にあたっての注意事項、企画の内容などを説明します！　ここから参加してお祭り気分になっちゃおう！！',
          description_en:
            "The VR JMoF BEGINS!!! We'll explain the schedule outline, precautions for participation, event contents and so on! From here on out, let's join in and get into a festive mood!!",
          access:
            'VR JMoFワールドの「ホール・ロビー」と「中庭」の配信画面、または公式動画配信から、企画の様子を視聴できます。',
          access_en:
            'You can watch this program on streaming screens located in the “Hall Lobby” and the“Courtyard” in the VR JMoF world, or on the official video streaming.',
          attention: ['※この企画は日本語のみで進行します。'],
          attention_en: [
            'Note: This program will be held in the Japanese language only.',
          ],
        },
        {
          id: 'tf_mirror',
          image: ref().value = require('@/assets/images/program/program-tfmirror.jpg'),
          platform: ['Resonite'],
          date: '常設企画',
          date_en: 'Permanent Program',
          title: 'TF Mirror / TF Monitor',
          title_en: 'TF Mirror / TF Monitor',
          description:
            'VR JMoF in JMoFホールロビーの一環として、最新鋭の体験型常設展示を行います。<br>・TF Mirror<br>立体視ディスプレイとメタバースを使用した最先端のバーチャルケモ化体験をお見逃しなく！<br>・TF Monitor<br>現実の賑わいをVRでも体験したい！ そんな思いから生まれた最新AI技術で、JMoFのロビーにいるたくさんのケモノをバーチャル空間にも出現させちゃおう！',
          description_en:
            "As part of the VR JMoF program at the hall lobby in JMoF, we exhibit interactive entertainment with cutting-edge technologies.<br>- TF Mirror<br>Don't miss the state-of-the-art virtual TransFurmation experience using stereoscopic displays and the Metaverse!<br>- TF Monitor<br>How can we experience the vibrant mood of the real venue through VR? This system with the latest AI makes it possible! Many critters at the hall lobby in JMoF appear in the virtual world, too!",
          access:
            '企画開催時間中、ResoniteのVR JMoFの公式セッションにJoinし、運営スタッフにお声かけください。',
          access_en:
            'If you wish to participate, please enter an official VR JMoF session in Resonite at the time of event.',
          attention: [
            '※JMoF 2024現地会場から企画に参加したい参加者の方は、企画開催時間中に「ホール・ロビー」の開催場所にお越しください。',
          ],
          attention_en: [
            '* For JMoF 2024 participants who join from the real venue, please come to the Hall Lobby at the time of event',
          ],
        },
        {
          id: 'tripfur',
          image: ref().value = require('@/assets/images/program/program-tfportal.jpg'),
          platform: ['Streaming'],
          date: '1月5日（金） 19:00〜22:00',
          date_en: 'January 5th (Fri.) 19:00 - 22:00 JST',
          datelabel: 'day_1',
          title: 'トーク＆スナック with TRIP FUR',
          title_en: 'Talk&Snack with TRIP FUR',
          description:
            'トーク＆スナックがTrip Furとコラボ！ 長旅を癒すChill Musicで構成されたDJを楽しみつつ、スナック片手に皆と談笑しよう。VR Jmofとの相互配信もあるよ！',
          description_en:
            "Talk & Snack collaborates with Trip Fur!Let's enjoy a DJ with chill music to soothe your long journey, and have a chat with everyone over a snack.There will also be mutual streaming with VR JMoF!",
        },
        {
          id: 'lounge1',
          image: ref().value = require('@/assets/images/program/program-lounge.jpg'),
          platform: ['Streaming'],
          date: '1月6日（土） 10:00〜21:00',
          date_en: 'January 6th (Sat.) 10:00 - 21:00 JST',
          datelabel: 'day_2',
          title: 'VRラウンジ',
          title_en: 'VR Lounge',
          description:
            'VR JMoFは今年もJMoFと同時開催、もちろん前回よりパワーアップした内容でお届けします！「リアル会場」となるロワジールホテル豊橋では、リアルでVRを感じられるVRラウンジを実施します。新たな創作の世界として広がるVRの世界で、交流を広めてみませんか？VRには興味はあるけれど体験する機会がない方、JMoFに来てみたけれどいつものVR仲間とも交流したい方、ぜひVRラウンジにお集まりください。',
          description_en:
            "VR JMoF is back this year, running alongside JMoF, with the upgraded experience from last time! At Loisir Hotel Toyohashi, which serves as our 'real venue', we'll be hosting a VR lounge that allows you to fully immerse yourself in the world of VR. Why not expand your interactions in this ever-expanding world of creativity? If you've been curious about VR but haven't had the chance to try it, or if you've come to JMoF and are looking to connect with your fellow VR enthusiasts, we encourage you to come together at the VR lounge.",
        },
        {
          id: 'jfcc',
          image: ref().value = require('@/assets/images/program/program-jfcc.png'),
          platform: ['Streaming'],
          date: '1月6日（土） 11:00〜13:00',
          date_en: 'January 6th (Sat.) 11:00 - 13:00 JST',
          datelabel: 'day_2',
          title: '着ぐるみクリエイターコンテスト',
          title_en: 'Japan Fursuit Creators’ Competition 2024',
          description:
            'JMoF 2024で7回目となる、着ぐるみクリエイターのためのコンテストを開催します。 技術や理想を追求したクリエイターたちがデビュー1年以内の新作の着ぐるみを披露し、審査員たちの評価により受賞者を決定します。今年は新たに新人賞も用意いたします！ クリエイターたちの勇姿を是非ご覧あれ！',
          description_en:
            "At JMoF 2024, we are excited to host the 7th edition of the contest dedicated to fursuit creators. Creators who’ve pursued their skill and ideals will show off a recent fursuit work, and the judges will score and declare a winner. We are introducing a new award called the 'Rookie Award' this year! We invite you to witness the fursuit creators’ masterpieces!",
        },
        {
          id: 'kigurumix1',
          image: ref().value = require('@/assets/images/program/program-kigurumix.jpg'),
          platform: ['Resonite'],
          date: '1月6日（土） 16:00〜17:00',
          date_en: 'January 6th (Sat.) 16:00 - 17:00 JST',
          datelabel: 'day_2',
          title: 'きぐるMixedReality ①',
          title_en: 'Just MixedReality on Fursuiting ①',
          description:
            'きぐるMixedRealityは、きぐるみをリアルタイム点群映像としてResoniteに表示することによってVR内の人もきぐるみをモフることができる画期的なコンテンツです。きぐるみのあなたもVR内の参加者にバーチャルでモフられよう！',
          description_en:
            'Just MixedReality on Fursuiting is a groundbreaking content that allows people in VR to virtually play with fursuiters by displaying fursuiters as a real-time point cloud image in Resonite. You, as a fursuiter, can enjoy playing with the participants in VR!',
          access:
            '企画開催時間中、ResoniteのVR JMoFの公式セッションにJoinし、運営スタッフにお声かけください。',
          access_en:
            'If you wish to participate, please enter an official VR JMoF session in Resonite at the time of event.',
          attention: [
            '※JMoF 2024現地会場から企画に参加したい参加者の方は、企画開催時間中に「ホール・ロビー」の開催場所にお越しください。',
          ],
          attention_en: [
            '* For JMoF 2024 participants who join from the real venue, please come to the Hall Lobby at the time of event',
          ],
        },
        {
          id: 'tfportal1',
          image: ref().value = require('@/assets/images/program/program-tfportal.jpg'),
          platform: ['Resonite'],
          date: '1月6日（土） 17:30〜18:00',
          date_en: 'January 6th (Sat.) 17:30 - 18:00 JST',
          datelabel: 'day_2',
          title: 'TF Portal ①',
          title_en: 'TF Portal ①',
          description:
            'TFPortalは、カメラから骨格推定を行ってVR内の人と遊べちゃうコンテンツです。今年はさらにアバターが追加される予定です！お楽しみにー！',
          description_en:
            'TF Portal is a content that allows you to play with people in VR using pose estimation from the camera. More avatars will be added this year! Please look forward to it!',
          access:
            '企画開催時間中、ResoniteのVR JMoFの公式セッションにJoinし、運営スタッフにお声かけください。',
          access_en:
            'If you wish to participate, please enter an official VR JMoF session in Resonite at the time of event.',
          attention: [
            '※JMoF 2024現地会場から企画に参加したい参加者の方は、企画開催時間中に「ホール・ロビー」の開催場所にお越しください。',
          ],
          attention_en: [
            '* For JMoF 2024 participants who join from the real venue, please come to the Hall Lobby at the time of event',
          ],
        },
        {
          id: 'whiteboard1',
          image: ref().value = require('@/assets/images/program/program-whiteboard.jpg'),
          platform: ['VRChat', 'Streaming'],
          date: '1月6日（土） 18:30〜19:30',
          date_en: 'January 6th (Sat.) 18:30 - 19:30 JST',
          datelabel: 'day_2',
          title: 'リアルVRホワイトボード ①',
          title_en: 'Real-VR Whiteboard ②',
          description:
            'JMoFでもVR JMoFでもおなじみのホワイトボード、今回もリアル／バーチャルどちらの会場からも書き込める「リアルVRホワイトボード」を時間限定で設置します！　イラストを通じてJMoF参加者と楽しく交流してみませんか？　VR JMoF期間中に全2回の開催を予定しています。',
          description_en:
            "While a whiteboard has been a familiar feature at both JMoF and VR JMoF, this time we'll set up a “Real VR Whiteboard”, where you can write from both the real and the virtual venue again! Why don't you have fun interacting with JMoF attendees through illustrations? We're going to hold a total of two sessions during the VR JMoF.",
          access:
            '企画開始時刻までに、公式動画配信にて、開催されるインスタンスの告知を行います。参加を希望される方は、「会場一覧」ページから告知されたインスタンスにJoinして、「桃の間」までお越しください。',
          access_en:
            'We will announce the venue instance on the official video streaming by the starting time. If you want to participate, please join the announced instance from the “World List” page and come to the Room “Momo”.',
        },
        {
          id: 'watchparty',
          image: ref().value = require('@/assets/images/program/program-watchparty.jpg'),
          platform: ['Streaming'],
          date: '1月6日（土） 20:00〜21:00',
          date_en: 'January 6th (Sat.) 20:00 - 21:00 JST',
          datelabel: 'day_2',
          title: 'みんなの動画上映会 in VR JMoF',
          title_en: "Watch Party for Everyfur's Productions",
          description:
            'みんなの制作動画上映会は、歌ってみた、踊ってみた、演奏してみた、○○やってみた……などなど、Furryにまつわる様々な映像を公募してリアル会場で上演する企画です。リアル会場のみでの放映企画でしたが、応募者の方にもご協力をいただいてVR JMoF内でも上映が決定！ケモノにまつわる動画作品で盛りあがろう！',
          description_en:
            "This program shows many kinds of submitted videos related to furry, such as singing, dancing, performing, trying anything, and so on! Why not promote your activities and works on the big screen and rich sound? Let's watch them in VR as well!",
        },
        {
          id: 'lounge2',
          image: ref().value = require('@/assets/images/program/program-lounge.jpg'),
          platform: ['Streaming'],
          date: '1月7日（日） 10:00〜18:30',
          date_en: 'January 7th (Sun.) 10:00 - 18:30 JST',
          datelabel: 'day_3',
          title: 'VRラウンジ',
          title_en: 'VR Lounge',
          description:
            'VR JMoFは今年もJMoFと同時開催、もちろん前回よりパワーアップした内容でお届けします！「リアル会場」となるロワジールホテル豊橋では、リアルでVRを感じられるVRラウンジを実施します。新たな創作の世界として広がるVRの世界で、交流を広めてみませんか？VRには興味はあるけれど体験する機会がない方、JMoFに来てみたけれどいつものVR仲間とも交流したい方、ぜひVRラウンジにお集まりください。',
          description_en:
            "VR JMoF is back this year, running alongside JMoF, with the upgraded experience from last time! At Loisir Hotel Toyohashi, which serves as our 'real venue', we'll be hosting a VR lounge that allows you to fully immerse yourself in the world of VR. Why not expand your interactions in this ever-expanding world of creativity? If you've been curious about VR but haven't had the chance to try it, or if you've come to JMoF and are looking to connect with your fellow VR enthusiasts, we encourage you to come together at the VR lounge.",
        },
        {
          id: 'kigurumix2',
          image: ref().value = require('@/assets/images/program/program-kigurumix.jpg'),
          platform: ['Resonite'],
          date: '1月7日（日） 10:30〜11:30',
          date_en: 'January 7th (Sun.) 10:30 - 11:30 JST',
          datelabel: 'day_3',
          title: 'きぐるMixedReality ②',
          title_en: 'Just MixedReality on Fursuiting ②',
          description:
            'きぐるMixedRealityは、きぐるみをリアルタイム点群映像としてResoniteに表示することによってVR内の人もきぐるみをモフることができる画期的なコンテンツです。きぐるみのあなたもVR内の参加者にバーチャルでモフられよう！',
          description_en:
            'Just MixedReality on Fursuiting is a groundbreaking content that allows people in VR to virtually play with fursuiters by displaying fursuiters as a real-time point cloud image in Resonite. You, as a fursuiter, can enjoy playing with the participants in VR!',
          access:
            '企画開催時間中、ResoniteのVR JMoFの公式セッションにJoinし、運営スタッフにお声かけください。',
          access_en:
            'If you wish to participate, please enter an official VR JMoF session in Resonite at the time of event.',
          attention: [
            '※JMoF 2024現地会場から企画に参加したい参加者の方は、企画開催時間中に「ホール・ロビー」の開催場所にお越しください。',
          ],
          attention_en: [
            '* For JMoF 2024 participants who join from the real venue, please come to the Hall Lobby at the time of TF Portal',
          ],
        },
        {
          id: 'tfportal2',
          image: ref().value = require('@/assets/images/program/program-tfportal.jpg'),
          platform: ['Resonite'],
          date: '1月7日（日） 12:00〜12:30',
          date_en: 'January 7th (Sun.) 12:00 - 12:30 JST',
          datelabel: 'day_3',
          title: 'TF Portal ②',
          title_en: 'TF Portal ②',
          description:
            'TFPortalは、カメラから骨格推定を行ってVR内の人と遊べちゃうコンテンツです。今年はさらにアバターが追加される予定です！お楽しみにー！',
          description_en:
            'TFPortal is a content that allows you to play with people in VR using pose estimation from the camera. More avatars will be added this year! Please look forward to it!',
          access:
            '企画開催時間中、ResoniteのVR JMoFの公式セッションにJoinし、運営スタッフにお声かけください。',
          access_en:
            'If you wish to participate, please enter an official VR JMoF session in Resonite at the time of event.',
          attention: [
            '※JMoF 2024現地会場から企画に参加したい参加者の方は、企画開催時間中に「ホール・ロビー」の開催場所にお越しください。',
          ],
          attention_en: [
            '* For JMoF 2024 participants who join from the real venue, please come to the Hall Lobby at the time of TF Portal',
          ],
        },
        {
          id: 'vrmeetup',
          image: ref().value = require('@/assets/images/program/program-photo.jpg'),
          platform: ['VRChat'],
          date: '1月7日（日） 13:30〜14:30',
          date_en: 'January 7th (Sun.) 13:30 - 14:30 JST',
          datelabel: 'day_3',
          title: 'VR users’ fursuit meetup',
          title_en: 'VR users’ fursuit meetup',
          description:
            'Virtual Reality...仮想現実。昨今の技術の発展により、人々は仮想と現実を行き来する。<br>Fursuit...着ぐるみ。昨今の情熱の昇華により、人々はケモノと人間を行き来する。<br><br>そして2024年1月、Virtual × Real × Fursuit　のmixは、さらなる大きなカタチへと進化していく。<br><br>Virtual Realtyに精通した人にならざるもの達よ。集え！<br><br>VR users’ fursuit meetup へ！ 今こそ次元の壁を越え、同じ時を刻もう！',
          description_en:
            "Virtual Reality... the recent advancement of technology has enabled people to move between the virtual and the real.<br>Fursuit... the recent sublimation of passions has enabled people to transition between the realm of the nonhumans and humans.<br><br>And in January 2024, the fusion of Virtual × Real × Fursuit is evolving into an even grander form.<br>Nonhumans well-versed in Virtual Reality, come together! Join the VR Users' Fursuit Meetup! It's time to transcend the boundaries of dimensions and share the same moment!",
          attention: [
            'VRユーザーを対象とした集合写真企画です。VRとコラボした集合写真を企画中に計3回撮影します。<br>VRに関わるキャラクターや撮影を行いたい方は是非お越しください。',
          ],
          attention_en: [
            "This is a group photo session for VR users. We are planning to capture a total of three group photos in collaboration with VR.<br>If you have a character related to VR or wish to shoot photos, please don't hesitate to join us.",
          ],
        },
        {
          id: 'whiteboard2',
          image: ref().value = require('@/assets/images/program/program-whiteboard.jpg'),
          platform: ['VRChat', 'Streaming'],
          date: '1月7日（日） 14:30〜15:30',
          date_en: 'January 7th (Sun.) 14:30 - 15:30 JST',
          datelabel: 'day_3',
          title: 'リアルVRホワイトボード ②',
          title_en: 'Real-VR Whiteboard ②',
          description:
            'JMoFでもVR JMoFでもおなじみのホワイトボード、今回もリアル／バーチャルどちらの会場からも書き込める「リアルVRホワイトボード」を時間限定で設置します！　イラストを通じてJMoF参加者と楽しく交流してみませんか？　VR JMoF期間中に全2回の開催を予定しています。',
          description_en:
            "While a whiteboard has been a familiar feature at both JMoF and VR JMoF, this time we'll set up a “Real VR Whiteboard”, where you can write from both the real and the virtual venue again! Why don't you have fun interacting with JMoF attendees through illustrations? We're going to hold a total of two sessions during the VR JMoF.",
          access:
            '企画開始時刻までに、公式動画配信にて、開催されるインスタンスの告知を行います。参加を希望される方は、「会場一覧」ページから告知されたインスタンスにJoinして、「桃の間」までお越しください。',
          access_en:
            'We will announce the venue instance on the official video streaming by the starting time. If you want to participate, please join the announced instance from the “World List” page and come to the Room “Momo”.',
        },
        {
          id: 'stage',
          image: ref().value = require('@/assets/images/program/program-stage.png'),
          platform: ['Streaming'],
          date: '1月7日（日） 16:00〜18:00',
          date_en: 'January 7th (Sun.) 16:00 - 18:00 JST',
          datelabel: 'day_3',
          title: 'JMoF2024 パフォーマンスステージ GhostBusters Furry Life',
          title_en: 'JMoF2024 Performance Stage GhostBusters Furry Life',
          description:
            '連日不可解な行動を目撃されていたJMoF主催、さとみ――。ゴーストバスターズのレオナとルードは、続々とパフォーマーが集うステージの上で彼と対峙する。会場のみんなと力を合わせ必死の抵抗を試みるなか、刻々と、イベント終盤に予定されたパフォーマンスショーの開始が迫っていた……！',
          description_en:
            'Satomi, Chairman of JMoF, has been witnessing mysterious behavior in recent days. Ghostbusters Reona and Lude confront him on the stage where performers are gathering one after another. As everyone in the hall joins forces and tries desperately to resist, the performance show, scheduled for the end of the event, is about to start at any moment!',
        },
        {
          id: 'closing',
          image: ref().value = require('@/assets/images/program/program-closing_jmof.jpg'),
          platform: ['Streaming'],
          date: '1月7日（日） 18:30〜19:00',
          date_en: 'January 7th (Sun.) 18:30 - 19:00 JST',
          datelabel: 'day_3',
          title: '閉会式',
          title_en: 'Closing Ceremony',
          description:
            'VR JMoF 2024を締めくくるプログラムです。スタッフや協力してくださった方々の紹介、そして閉会宣言を行ないます。ぜひご参加ください。',
          description_en:
            'This is the program to complete the VR JMoF 2024. We will introduce the staff and contributors, and conclude the event. Please come and join us.',
          access:
            'VR JMoFワールドの「ホール・ロビー」と「中庭」の配信画面、または公式動画配信から、企画の様子を視聴できます。',
          access_en:
            'You can watch this program on streaming screens located in the “Hall Lobby” and the“Courtyard” in the VR JMoF world, or on the official video streaming.',
          attention: ['※この企画は日本語のみで進行します。'],
          attention_en: [
            'Note: This program will be held in the Japanese language only.',
          ],
        },
        {
          id: 'dead_dog_party',
          image: ref().value = require('@/assets/images/program/program-ddp.jpg'),
          platform: ['VRChat', 'Resonite'],
          date: '1月7日（日） 20:30〜22:30',
          date_en: 'January 7th (Sun.) 20:30 - 22:30 JST',
          datelabel: 'day_3',
          title: 'デッドドッグパーティー',
          title_en: 'Dead Dog Party',
          description:
            'えっ！！！　VR JMoF終わってないじゃん！！？！？　そう、デッドドッグパーティーが残っている……。いうなれば公式2次会です。なんとVRならみんな安全にお酒が飲めるッ！！　羽目を外しても自己責任！！　末法の世の終わらない飲み会へようこそ！！！！！！',
          description_en:
            "Wait, what? VR JMoF hasn't finished yet!? That's right, we still have the Dead Dog Party left... It's so to speak the official after-party. What's more, everyone can drink safely in VR! You're on your own even if you cut loose! Welcome to the never-ending drinking party in this crazy world!!",
          access:
            '閉会式後に一度休憩して、オフロに入って、酒とツマミを用意して、その画像を「#VR_MELTDOWN」のハッシュタグをつけてTwitterにアップロードして、もう一度VR JMoFにJoinだ！！！',
          access_en:
            'After the Closing Ceremony, take a rest, take a bath, prepare some drinks and snacks, upload that image to Twitter with the hashtag #VR_MELTDOWN, and join VR JMoF once again!',
          attention: ['※閉会後のため、スタッフによる会場の管理は行いません。'],
          attention_en: [
            'Note: Instances/sessions will not be managed by staff as the event is closed.',
          ],
        },
      ],
    };
  },
  setup() {
    const i18n = useI18n();
    const locale = i18n.locale;
    return {
      locale,
    };
  },
});
