<template>
  <div class="heading">
    <h1 v-if="$props.tag === 'h1'"><slot></slot></h1>
    <h2 v-if="$props.tag === 'h2'"><slot></slot></h2>
    <h3 v-if="$props.tag === 'h3'"><slot></slot></h3>
    <h4 v-if="$props.tag === 'h4'"><slot></slot></h4>
    <h5 v-if="$props.tag === 'h5'"><slot></slot></h5>
    <h6 v-if="$props.tag === 'h6'"><slot></slot></h6>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Heading',
  props: {
    tag: {
      type: String,
    },
  },
});
</script>

<style lang="scss" scoped>
.heading {
  scroll-margin-top: 80px;
}
h1 {
  font-size: 24px;
}

h2 {
  font-size: 18px;
}

@media (min-width: 800px) {
  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 20px;
  }
}
</style>
