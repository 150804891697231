<template>
  <div class="switch">
    Lang:<span v-on:click="switchLanguage('ja', this.$route.path)">JA</span
    >/<span v-on:click="switchLanguage('en', this.$route.path)">EN</span>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useI18n } from '@/plugins/i18n';
import router from '@/router';

// FIXME: ゴリ押しがすぎる
export default defineComponent({
  name: 'TheLanguageSwitch',
  setup() {
    const i18n = useI18n();

    const getDeleteEnUrl = url => {
      return url.replace('/en/', '/');
    };
    const switchLanguage = (locale, path) => {
      i18n.locale.value = locale;
      let en = '';
      if (locale === 'en') {
        en = '/en';
      }
      router.push(`${en}${getDeleteEnUrl(path)}?switch=${locale}`);
    };

    return {
      i18n,
      switchLanguage,
    };
  },
});
</script>

<style lang="scss">
@import '~@/sass/constant/_common.scss';

.switch {
  font-size: 14px;

  span {
    padding: 0 4px;
    cursor: pointer;
    color: $color-black;
    font-weight: bold;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
@media (min-width: 800px) {
  .switch {
    font-size: 16px;
  }
}
</style>
