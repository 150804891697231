
import { defineComponent } from 'vue';
import { useI18n } from '@/plugins/i18n';

export default defineComponent({
  name: 'ProgramCard',
  props: {
    data: {
      type: Array,
    },
    i18n: {
      type: Object,
    },
  },
  setup() {
    const i18n = useI18n();
    const locale = i18n.locale;
    return {
      locale,
    };
  },
});
