
import { defineComponent } from 'vue';
import FaqContactTemplate from '@/components/templates/FaqContactTemplate.vue';
import { useI18n } from '@/plugins/i18n';
import JaMessages from '@/assets/i18n/ja/faq_contact.json';
import EnMessages from '@/assets/i18n/en/faq_contact.json';
import { pageview } from 'vue-gtag';

export default defineComponent({
  name: 'FaqContact',
  components: { FaqContactTemplate },
  methods: {
    track() {
      pageview({ page_path: '/faq_contact' });
    },
  },
  setup() {
    const i18n = useI18n();
    i18n.$setMessages(JaMessages, EnMessages);

    return {
      i18n,
    };
  },
});
