import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueGtag from 'vue-gtag';

console.log(
  '%c ',
  'background-image: url("https://cdn.discordapp.com/attachments/491302411898585088/929193425146871908/vrjmof.png"); padding: 10px 62px',
);
console.log('%cVR JMoF 2022!', 'font-size: 24px; color: #f1f1f1;');
// VUE_APP_MODE=development

const app = createApp(App);

if (process.env.VUE_APP_MODE === 'production') {
  app.use(
    VueGtag,
    {
      config: { id: 'G-7XFYRT9BTL' },
    },
    router,
  );
}

app
  .use(store)
  .use(router)
  .mount('#app');
