
import { defineComponent } from 'vue';
import TheDetailPageHeader from '@/components/organisms/detailPage/TheDetailPageHeader.vue';
import TheDetailPageBody from '@/components/organisms/detailPage/TheDetailPageBody.vue';
import Heading from '@/components/atoms/Heading.vue';
import SignalText from '@/components/atoms/SignalText.vue';

export default defineComponent({
  name: 'TermsTemplate',
  components: {
    TheDetailPageHeader,
    TheDetailPageBody,
    Heading,
    SignalText,
  },
  props: {
    i18n: {
      type: Object,
    },
  },
});
