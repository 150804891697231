import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-315a204c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page-credit" }
const _hoisted_2 = { class: "credit-list" }
const _hoisted_3 = { class: "credit-image" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "credit-text" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = ["href"]
const _hoisted_8 = { key: 1 }
const _hoisted_9 = ["href"]
const _hoisted_10 = { key: 2 }
const _hoisted_11 = ["href"]
const _hoisted_12 = { key: 3 }
const _hoisted_13 = ["href"]
const _hoisted_14 = { key: 4 }
const _hoisted_15 = ["href"]
const _hoisted_16 = { class: "credit-list" }
const _hoisted_17 = { class: "credit-image" }
const _hoisted_18 = ["src"]
const _hoisted_19 = { class: "credit-text" }
const _hoisted_20 = { key: 0 }
const _hoisted_21 = ["href"]
const _hoisted_22 = { key: 1 }
const _hoisted_23 = ["href"]
const _hoisted_24 = { key: 2 }
const _hoisted_25 = ["href"]
const _hoisted_26 = { key: 3 }
const _hoisted_27 = ["href"]
const _hoisted_28 = { key: 4 }
const _hoisted_29 = ["href"]
const _hoisted_30 = { class: "update-date" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheDetailPageHeader = _resolveComponent("TheDetailPageHeader")!
  const _component_SignalText = _resolveComponent("SignalText")!
  const _component_Heading = _resolveComponent("Heading")!
  const _component_TheDetailPageBody = _resolveComponent("TheDetailPageBody")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TheDetailPageHeader, { class: "page-others" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$props.i18n.$text('page_title')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_TheDetailPageBody, null, {
      default: _withCtx(() => [
        _createElementVNode("section", _hoisted_1, [
          _createVNode(_component_SignalText, { signal: "caution" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$props.i18n.$text('caution')), 1)
            ]),
            _: 1
          }),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mainStaff, (item, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
              _createVNode(_component_Heading, {
                class: "credit-heading",
                tag: "h2"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$props.i18n.locale.value === 'ja' ? item.label_ja : item.label_en), 1)
                ]),
                _: 2
              }, 1024),
              _createElementVNode("ul", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.member, (member) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: member.icon
                  }, [
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("img", {
                        src: member.icon,
                        alt: ""
                      }, null, 8, _hoisted_4)
                    ]),
                    _createElementVNode("dl", _hoisted_5, [
                      _createElementVNode("dt", null, _toDisplayString(_ctx.$props.i18n.locale.value === 'ja'
                    ? member.name_ja
                    : member.name_en), 1),
                      (member.booth)
                        ? (_openBlock(), _createElementBlock("dd", _hoisted_6, [
                            _createElementVNode("a", {
                              href: member.booth,
                              target: "_blank",
                              class: "link-bt"
                            }, "Booth", 8, _hoisted_7)
                          ]))
                        : _createCommentVNode("", true),
                      (member.twitter)
                        ? (_openBlock(), _createElementBlock("dd", _hoisted_8, [
                            _createElementVNode("a", {
                              href: member.twitter,
                              target: "_blank",
                              class: "link-tw"
                            }, "Twitter", 8, _hoisted_9)
                          ]))
                        : _createCommentVNode("", true),
                      (member.youtube)
                        ? (_openBlock(), _createElementBlock("dd", _hoisted_10, [
                            _createElementVNode("a", {
                              href: member.youtube,
                              target: "_blank"
                            }, "YouTube", 8, _hoisted_11)
                          ]))
                        : _createCommentVNode("", true),
                      (member.skeb)
                        ? (_openBlock(), _createElementBlock("dd", _hoisted_12, [
                            _createElementVNode("a", {
                              href: member.skeb,
                              target: "_blank"
                            }, "Skeb", 8, _hoisted_13)
                          ]))
                        : _createCommentVNode("", true),
                      (member.web)
                        ? (_openBlock(), _createElementBlock("dd", _hoisted_14, [
                            _createElementVNode("a", {
                              href: member.web,
                              target: "_blank"
                            }, "Website", 8, _hoisted_15)
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]))
                }), 128))
              ])
            ], 64))
          }), 128)),
          _createVNode(_component_Heading, {
            class: "credit-heading",
            tag: "h2"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$props.i18n.$text('h_collaborator')), 1)
            ]),
            _: 1
          }),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subStaff, (item, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
              _createVNode(_component_Heading, {
                class: "credit-heading",
                tag: "h3"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$props.i18n.locale.value === 'ja' ? item.label_ja : item.label_en), 1)
                ]),
                _: 2
              }, 1024),
              _createElementVNode("ul", _hoisted_16, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.member, (member) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: member.icon
                  }, [
                    _createElementVNode("div", _hoisted_17, [
                      _createElementVNode("img", {
                        src: member.icon,
                        alt: ""
                      }, null, 8, _hoisted_18)
                    ]),
                    _createElementVNode("dl", _hoisted_19, [
                      _createElementVNode("dt", null, _toDisplayString(_ctx.$props.i18n.locale.value === 'ja'
                    ? member.name_ja
                    : member.name_en), 1),
                      (member.booth)
                        ? (_openBlock(), _createElementBlock("dd", _hoisted_20, [
                            _createElementVNode("a", {
                              href: member.booth,
                              target: "_blank",
                              class: "link-bt"
                            }, "Booth", 8, _hoisted_21)
                          ]))
                        : _createCommentVNode("", true),
                      (member.twitter)
                        ? (_openBlock(), _createElementBlock("dd", _hoisted_22, [
                            _createElementVNode("a", {
                              href: member.twitter,
                              target: "_blank",
                              class: "link-tw"
                            }, "Twitter", 8, _hoisted_23)
                          ]))
                        : _createCommentVNode("", true),
                      (member.youtube)
                        ? (_openBlock(), _createElementBlock("dd", _hoisted_24, [
                            _createElementVNode("a", {
                              href: member.youtube,
                              target: "_blank"
                            }, "YouTube", 8, _hoisted_25)
                          ]))
                        : _createCommentVNode("", true),
                      (member.skeb)
                        ? (_openBlock(), _createElementBlock("dd", _hoisted_26, [
                            _createElementVNode("a", {
                              href: member.skeb,
                              target: "_blank"
                            }, "Skeb", 8, _hoisted_27)
                          ]))
                        : _createCommentVNode("", true),
                      (member.web)
                        ? (_openBlock(), _createElementBlock("dd", _hoisted_28, [
                            _createElementVNode("a", {
                              href: member.web,
                              target: "_blank"
                            }, "Website", 8, _hoisted_29)
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]))
                }), 128))
              ])
            ], 64))
          }), 128)),
          _createVNode(_component_SignalText, { signal: "caution" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$props.i18n.$text('caution_2')), 1)
            ]),
            _: 1
          }),
          _createElementVNode("p", _hoisted_30, _toDisplayString(_ctx.$props.i18n.$text('update_date')), 1)
        ])
      ]),
      _: 1
    })
  ], 64))
}