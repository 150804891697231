import axios, { AxiosInstance } from 'axios';

export const VERSIONS = {
  V1: 'v1',
};
export function getAxios(apiVersion: string): AxiosInstance {
  return axios.create({
    baseURL: `${process.env.VUE_APP_API_ENDPOINT}/${apiVersion}`,
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: 'json',
  });
}
