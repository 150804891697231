import { RouteRecordRaw } from 'vue-router';
import General from '@/views/General.vue';
import Program from '@/components/pages/program/Program.vue';
import Schedule from '@/components/pages/program/Schedule.vue';
import ApplyAvatarPoster from '@/components/pages/program/ApplyAvatarPoster.vue';

const PROGRAM_ROUTES: Array<RouteRecordRaw> = [
  {
    path: '/program',
    alias: '/en/program',
    name: 'ProgramRoot',
    component: General,
    children: [
      {
        path: '',
        name: 'Program',
        component: Program,
        meta: {
          title: 'VR JMoF｜企画紹介',
          enTitle: 'VR JMoF | Program',
        },
      },
      {
        path: 'schedule',
        name: 'Schedule',
        component: Schedule,
        meta: { title: 'スケジュール', enTitle: 'Schedule' },
      },
      {
        path: 'apply_avatar_poster',
        name: 'ApplyAvatarPoster',
        component: ApplyAvatarPoster,
        meta: {
          title: 'アバター&ポスター募集',
          enTitle: 'Avatar & Poster Submissions',
        },
      },
    ],
  },
];

export default PROGRAM_ROUTES;
