
import { defineComponent } from 'vue';

import TheDetailPageHeader from '@/components/organisms/detailPage/TheDetailPageHeader.vue';
import TheDetailPageBody from '@/components/organisms/detailPage/TheDetailPageBody.vue';
import InstanceList from '@/components/organisms/instance/InstanceList.vue';
import Heading from '@/components/atoms/Heading.vue';
// import SignalText from '@/components/atoms/SignalText.vue';
import LinkButton from '@/components/organisms/utils/LinkButton.vue';

export default defineComponent({
  name: 'InstancesTemplate',
  components: {
    TheDetailPageHeader,
    TheDetailPageBody,
    InstanceList,
    Heading,
    // SignalText,
    LinkButton,
  },
  props: {
    i18n: {
      type: Object,
    },
    eventList: {
      type: Object,
    },
    permanentInstanceList: {
      type: Array,
    },
    neosList: {
      type: Array,
    },
  },
});
