
import { defineComponent } from 'vue';
import TopLogo from '@/components/organisms/top/TopLogo.vue';
import TopNews from '@/components/organisms/top/TopNews.vue';
import ContentBox from '@/components/molecules/ContentBox.vue';
import Heading from '@/components/atoms/Heading.vue';
import LinkButton from '@/components/organisms/utils/LinkButton.vue';
import { getIsIos } from '@/libs/utils/device';
import { useI18n } from '@/plugins/i18n';

export default defineComponent({
  name: 'TopTemplate',
  components: {
    TopLogo,
    TopNews,
    ContentBox,
    Heading,
    LinkButton,
  },
  props: {
    i18n: {
      type: Object,
    },
  },
  mounted: function() {
    if (getIsIos()) {
      document.body.className = 'is-ios';
    }
  },
  setup() {
    const i18n = useI18n();
    const locale = i18n.locale;
    return {
      locale,
    };
  },
});
