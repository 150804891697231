
import { defineComponent } from 'vue';
import InstanceListItem from '@/components/molecules/instance/InstanceListItem.vue';
import Heading from '@/components/atoms/Heading.vue';
import { InstanceInterface } from '@/libs/Interfaces/instanceInterface';
import { useI18n } from '@/plugins/i18n';
import { EVENT_NAME } from '@/libs/constants/event';
import { INSTANCE_TYPE } from '@/libs/constants/instance';

export default defineComponent({
  name: 'InstanceList',
  components: { InstanceListItem, Heading },
  props: {
    instanceList: {
      type: Array as () => InstanceInterface[],
    },
    showJoinButton: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const i18n = useI18n();
    const getEventName = (eventId: string) => {
      return eventId in EVENT_NAME
        ? EVENT_NAME[eventId][i18n.locale.value]
        : '';
    };
    const getInstanceName = (type: number, uid: number, eventId: string) => {
      if (eventId === 'neos_josetsu') {
        return `${
          i18n.locale.value === 'ja' ? 'セッション #' : 'Session #'
        }${uid + 1}`;
      } else if (
        type === INSTANCE_TYPE.PERMANENT ||
        eventId.indexOf('kemoclub') > -1
      ) {
        return `${
          i18n.locale.value === 'ja' ? 'インスタンス #' : 'Instance #'
        }${uid + 1}`;
      } else if (type === INSTANCE_TYPE.MAIN) {
        return i18n.locale.value === 'ja'
          ? 'メインインスタンス'
          : 'Main Instance';
      } else if (type === INSTANCE_TYPE.STREAM) {
        return `${
          i18n.locale.value === 'ja' ? '配信視聴用 #' : 'Stream-Watching Use #'
        }${uid}`;
      }
      return '';
    };
    return {
      getEventName,
      getInstanceName,
    };
  },
});
