import { getAxios, VERSIONS } from '@/plugins/axios';
import { InstanceInterface } from '@/libs/Interfaces/instanceInterface';

export function getAllInstance(): Promise<any> {
  return getAxios(VERSIONS.V1)
    .get('/instance')
    .then(result => {
      return result.data;
    });
}
