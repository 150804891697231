
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DialogBox',
  emits: {
    close: () => true,
  },
  setup(_props, { emit }) {
    return {
      dialogClose() {
        emit('close');
      },
    };
  },
});
