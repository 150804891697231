import { RouteRecordRaw } from 'vue-router';
import Single from '@/views/Single.vue';
import Prologue from '@/components/pages/prologue/Prologue.vue';

const PROLOGUE_ROUTES: Array<RouteRecordRaw> = [
  {
    path: '/prologue',
    alias: '/en/prologue',
    name: 'prologueRoot',
    component: Single,
    children: [
      {
        path: '',
        name: 'prologue',
        component: Prologue,
        meta: {
          title: 'VR JMoF Prologue',
          enTitle: 'VR JMoF Prologue',
        },
      },
    ],
  },
];

export default PROLOGUE_ROUTES;
