import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "news-inner" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newsList, (item, index) => {
      return (_openBlock(), _createElementBlock("dl", { key: index }, [
        (_ctx.locale === 'ja' && item.type !== 'en')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("dt", null, _toDisplayString(item.title_ja), 1),
              _createElementVNode("dd", {
                innerHTML: item.body_ja
              }, null, 8, _hoisted_2)
            ], 64))
          : _createCommentVNode("", true),
        (_ctx.locale === 'en' && item.type !== 'ja')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createElementVNode("dt", null, _toDisplayString(item.title_en), 1),
              _createElementVNode("dd", {
                innerHTML: item.body_en
              }, null, 8, _hoisted_3)
            ], 64))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}