
import { defineComponent, ref, onMounted } from 'vue';
// import { defineComponent, ref } from 'vue';
import InstancesTemplate from '@/components/templates/access/InstancesTemplate.vue';
import { useI18n } from '@/plugins/i18n';
import JaMessages from '@/assets/i18n/ja/access/instances.json';
import EnMessages from '@/assets/i18n/en/access/instances.json';

import { getAllInstance } from '@/libs/apis/instance';
import { InstanceInterface } from '@/libs/Interfaces/instanceInterface';
import { pageview } from 'vue-gtag';

export default defineComponent({
  name: 'Instances',
  components: { InstancesTemplate },
  methods: {
    track() {
      pageview({ page_path: '/access/instances' });
    },
  },
  setup() {
    const eventList = ref<{
      [key: string]: InstanceInterface[];
    }>({});

    const permanentInstanceList = ref<InstanceInterface[]>([]);
    const neosList = ref<InstanceInterface[]>([]);

    const i18n = useI18n();
    i18n.$setMessages(JaMessages, EnMessages);

    onMounted(() => {
      getAllInstance().then(response => {
        response.forEach((instance: InstanceInterface) => {
          // FIXME マジックナンバー
          if (
            instance.eventId === 'photo' ||
            instance.eventId === 'poster' ||
            instance.eventId === 'avatar' ||
            instance.eventId === 'ddp'
          )
            return;
          if (
            !eventList.value[instance.eventId] &&
            instance.eventId !== 'josetsu' &&
            instance.eventId !== 'neos_josetsu'
          ) {
            eventList.value[instance.eventId] = [];
          }
          if (instance.eventId === 'josetsu') {
            permanentInstanceList.value.push(instance);
          } else if (instance.eventId === 'neos_josetsu') {
            neosList.value.push(instance);
          } else {
            eventList.value[instance.eventId].push(instance);
          }
        });
      });
    });

    return {
      neosList,
      eventList,
      permanentInstanceList,
      i18n,
    };
  },
});
