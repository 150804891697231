import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouteLocationNormalized,
  RouteLocationNormalizedLoaded,
} from 'vue-router';
import General from '@/views/General.vue';
import Top from '@/components/pages/Top.vue';
import FaqContact from '@/components/pages/FaqContact.vue';
import Credit from '@/components/pages/Credit.vue';
import TERMS_ROUTES from '@/router/terms';
import PROGRAM_ROUTES from '@/router/program';
import PROLOGUE_ROUTES from '@/router/prologue';
import PROLOGUE2024_ROUTES from '@/router/2024-prologue';
import ACCESS_ROUTES from '@/router/access';
import { getUserLocale } from '@/libs/utils/locale';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    alias: '/en',
    name: 'Root',
    component: General,
    children: [
      {
        path: '',
        name: 'Top',
        component: Top,
      },
      {
        path: 'faq_contact',
        name: 'FaqContact',
        component: FaqContact,
        meta: { title: 'FAQ・お問い合わせ', enTitle: 'FAQ & Contact' },
      },
      {
        path: 'credit',
        name: 'Credit',
        component: Credit,
        meta: { title: 'スタッフ・協力者', enTitle: 'Staff & Collaborators' },
      },
    ],
  },
  ...TERMS_ROUTES,
  ...PROGRAM_ROUTES,
  ...PROLOGUE_ROUTES,
  ...PROLOGUE2024_ROUTES,
  ...ACCESS_ROUTES,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  async scrollBehavior(
    to: RouteLocationNormalized,
    from: RouteLocationNormalizedLoaded,
    savedPosition: any,
  ) {
    const findElement = async (hash: string, x: number) => {
      return (
        document.querySelector(hash) ||
        new Promise((resolve, reject) => {
          if (x > 50) {
            return resolve(void 0);
          }
          setTimeout(() => {
            resolve(findElement(hash, ++x || 1));
          }, 100);
        })
      );
    };

    if (to.hash) {
      const element: any = await findElement(to.hash, 0);
      const margin = 100; // それとなく上に取っとく
      return { top: element.offsetTop - margin };
    }

    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const locale = getUserLocale();

  let nextPath = '';

  if (!to.query.switch) {
    if (!to.path.match(/^\/en/g)) {
      if (from.path.match(/^\/en/g)) {
        nextPath = `/en${to.path}`;
      } else {
        if (!from.matched.length && locale === 'en') {
          nextPath = `/en${to.path}`;
        }
      }
    }
  }

  const setTitle = (match: any) => {
    const siteTitle = 'VR JMoF';
    const title = match ? to.meta.enTitle : to.meta.title;

    const pageTitle = !title ? siteTitle : title + ' | ' + siteTitle;
    return (window.document.title = pageTitle);
  };

  setTitle(to.path.match(/^\/en/g));

  if (nextPath) {
    next({ path: nextPath, hash: to.hash });
  } else {
    next();
  }
});

export default router;
