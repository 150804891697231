import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f338a10"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page-instances" }
const _hoisted_2 = { class: "access-navi" }
const _hoisted_3 = { class: "item" }
const _hoisted_4 = {
  class: "link",
  href: "#vrchat"
}
const _hoisted_5 = { class: "item" }
const _hoisted_6 = {
  class: "link",
  href: "#neosvr"
}
const _hoisted_7 = { class: "vrchat-world-link" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheDetailPageHeader = _resolveComponent("TheDetailPageHeader")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Heading = _resolveComponent("Heading")!
  const _component_InstanceList = _resolveComponent("InstanceList")!
  const _component_LinkButton = _resolveComponent("LinkButton")!
  const _component_TheDetailPageBody = _resolveComponent("TheDetailPageBody")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TheDetailPageHeader, { class: "page-instances" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$props.i18n.$text('page_title')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_TheDetailPageBody, null, {
      default: _withCtx(() => [
        _createElementVNode("section", _hoisted_1, [
          _createElementVNode("p", null, [
            _createTextVNode(_toDisplayString(_ctx.$props.i18n.$text('read_access', 'text_1')), 1),
            _createVNode(_component_router_link, { to: "/access" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$props.i18n.$text('read_access', 'link')), 1)
              ]),
              _: 1
            }),
            _createTextVNode(_toDisplayString(_ctx.$props.i18n.$text('read_access', 'text_2')), 1)
          ]),
          _createElementVNode("ul", _hoisted_2, [
            _createElementVNode("li", _hoisted_3, [
              _createElementVNode("a", _hoisted_4, _toDisplayString(_ctx.$props.i18n.$text('menu', 'vrchat')), 1)
            ]),
            _createElementVNode("li", _hoisted_5, [
              _createElementVNode("a", _hoisted_6, _toDisplayString(_ctx.$props.i18n.$text('menu', 'neos')), 1)
            ])
          ]),
          _createVNode(_component_Heading, {
            tag: "h2",
            class: "heading-platform",
            id: "vrchat"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$props.i18n.$text('menu', 'vrchat')), 1)
            ]),
            _: 1
          }),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.eventList, (instanceList) => {
            return (_openBlock(), _createBlock(_component_InstanceList, {
              key: instanceList[0].eventId,
              "instance-list": instanceList
            }, null, 8, ["instance-list"]))
          }), 128)),
          _createVNode(_component_InstanceList, { "instance-list": _ctx.permanentInstanceList }, null, 8, ["instance-list"]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_LinkButton, {
              href: "https://vrchat.com/home/world/wrld_aa52c1d8-812f-442e-b3d5-c4b354dc7600",
              "is-external": true
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$props.i18n.$text('world_link')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_LinkButton, {
              href: "https://vrchat.com/home/world/wrld_7f5ed740-b06d-4beb-a8c8-2d0c95cae981",
              "is-external": true
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$props.i18n.$text('quest_link')), 1)
              ]),
              _: 1
            })
          ]),
          _createVNode(_component_Heading, {
            tag: "h2",
            class: "heading-platform",
            id: "neosvr"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$props.i18n.$text('menu', 'neos')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_LinkButton, {
            href: "/access#resonite",
            "is-external": false
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$props.i18n.$text('access_link')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_InstanceList, {
            "instance-list": _ctx.neosList,
            "show-join-button": false
          }, null, 8, ["instance-list"])
        ])
      ]),
      _: 1
    })
  ], 64))
}