
import { defineComponent } from 'vue';
import ProgramTemplate from '@/components/templates/program/ProgramTemplate.vue';
import { useI18n } from '@/plugins/i18n';
import JaMessages from '@/assets/i18n/ja/program/program.json';
import EnMessages from '@/assets/i18n/en/program/program.json';
import { pageview } from 'vue-gtag';

export default defineComponent({
  name: 'Program',
  components: { ProgramTemplate },
  methods: {
    track() {
      pageview({ page_path: '/program' });
    },
  },
  setup() {
    const i18n = useI18n();
    i18n.$setMessages(JaMessages, EnMessages);

    return {
      i18n,
    };
  },
});
