
import { defineComponent } from 'vue';
import TermsTemplate from '@/components/templates/terms/TermsTemplate.vue';
import { useI18n } from '@/plugins/i18n';
import JaMessages from '@/assets/i18n/ja/terms/terms.json';
import EnMessages from '@/assets/i18n/en/terms/terms.json';
import { pageview } from 'vue-gtag';

export default defineComponent({
  name: 'Terms',
  components: { TermsTemplate },
  methods: {
    track() {
      pageview({ page_path: '/terms' });
    },
  },
  setup() {
    const i18n = useI18n();
    i18n.$setMessages(JaMessages, EnMessages);

    return {
      i18n,
    };
  },
});
