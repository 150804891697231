
import { defineComponent } from 'vue';
import AccessTemplate from '@/components/templates/access/AccessTemplate.vue';
import { useI18n } from '@/plugins/i18n';
import JaMessages from '@/assets/i18n/ja/access/access.json';
import EnMessages from '@/assets/i18n/en/access/access.json';
import { pageview } from 'vue-gtag';

export default defineComponent({
  name: 'Access',
  components: { AccessTemplate },
  methods: {
    track() {
      pageview({ page_path: '/access' });
    },
  },
  setup() {
    const i18n = useI18n();
    i18n.$setMessages(JaMessages, EnMessages);

    return {
      i18n,
    };
  },
});
