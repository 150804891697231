
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LinkButton',
  props: {
    href: {
      type: String,
    },
    isExternal: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
});
