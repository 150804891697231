import { ref, provide, inject } from 'vue';

/* eslint-disable @typescript-eslint/no-explicit-any*/
interface I18nConfigInterface {
  locale: string;
  messages?: {
    [key: string]: any;
  };
  $allText?: () => {};
  $text?: () => {};
  $setMessages?: () => {};
}
/* eslint-enable @typescript-eslint/no-explicit-any*/

const i18nSymbol = Symbol();

export function provideI18n(i18nConfig: I18nConfigInterface) {
  const i18n = {
    locale: ref(i18nConfig.locale),
    messages: i18nConfig.messages,
    $allText(): object {
      if (!this.messages) return {};
      return this.messages[this.locale.value];
    },
    $text(...keys: string[]): string {
      if (!this.messages) return '';
      if (keys.length <= 0) return '';
      let response = this.messages[this.locale.value];
      keys.forEach(key => {
        response = response[key];
      });
      return response;
    },
    $setMessages(ja: object, en: object) {
      if (!ja || !en) return;
      this.messages = {
        ja: {
          ...ja,
        },
        en: {
          ...en,
        },
      };
    },
  };

  provide(i18nSymbol, i18n);
}

/* eslint-disable @typescript-eslint/no-explicit-any*/
export function useI18n(): any {
  const i18n = inject(i18nSymbol);
  if (!i18n) throw new Error('No i18n provided!!!');

  return i18n;
}
/* eslint-enable @typescript-eslint/no-explicit-any*/
