import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass('button' + (_ctx.$props.isDisabled ? ' disabled' : ''))
  }, [
    (!_ctx.$props.isExternal)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          to: _ctx.$props.href
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 3
        }, 8, ["to"]))
      : (_openBlock(), _createElementBlock("a", {
          key: 1,
          href: _ctx.$props.href,
          target: "_blank"
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 8, _hoisted_1))
  ], 2))
}