
import { defineComponent } from 'vue';
import { useI18n } from '@/plugins/i18n';

export default defineComponent({
  name: 'TheFooter',
  setup() {
    const i18n = useI18n();
    const locale = i18n.locale;

    // FIXME 書き方が微妙
    const footerNav = {
      ja: {
        terms: '参加規約',
        privacyPolicy: '個人情報の取り扱いについて',
        faqContact: 'FAQ・お問い合わせ',
        credit: 'スタッフ・協力者',
      },
      en: {
        terms: 'Terms',
        privacyPolicy: 'Privacy Policy',
        faqContact: 'FAQ & Contact',
        credit: 'Staff & Collaborators',
      },
    };

    return {
      footerNav,
      locale,
    };
  },
});
