
import { defineComponent } from 'vue';
import TheLanguageSwitch from '@/components/organisms/utils/TheLanguageSwitch.vue';
import { useI18n } from '@/plugins/i18n';

export default defineComponent({
  name: 'TheHeader',
  components: { TheLanguageSwitch },
  setup() {
    const i18n = useI18n();
    const locale = i18n.locale;

    const headerNav = {
      ja: {
        access: 'アクセス方法',
        program: '企画紹介',
        schedule: 'スケジュール',
        faqContact: 'FAQ・お問い合わせ',
        instances: '会場一覧',
        submissions: 'アバター＆ポスター募集',
      },
      en: {
        access: 'How to Access',
        program: 'Program',
        schedule: 'Schedule',
        faqContact: 'FAQ & Contact',
        instances: 'World List',
        submissions: 'Avatar & Poster Submissions',
      },
    };

    return {
      headerNav,
      locale,
    };
  },
});
