
import { defineComponent } from 'vue';
import { INSTANCE_TYPE } from '@/libs/constants/instance';
import { useI18n } from '@/plugins/i18n';

export default defineComponent({
  name: 'InstanceList',
  props: {
    instanceName: {
      type: String,
    },
    maxUser: {
      type: Number,
    },
    currentUser: {
      type: Number,
    },
    isFull: {
      type: Number,
    },
    isVisible: {
      type: Number,
    },
    instanceType: {
      type: Number,
    },
    instancePageUrl: {
      type: String,
    },
    showJoinButton: {
      type: Boolean,
    },
  },
  setup() {
    const i18n = useI18n();
    const locale = i18n.locale;
    return {
      locale,
      INSTANCE_TYPE,
    };
  },
});
