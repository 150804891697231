import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4cb2ca54"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheDetailPageHeader = _resolveComponent("TheDetailPageHeader")!
  const _component_TheDetailPageBody = _resolveComponent("TheDetailPageBody")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TheDetailPageHeader, { class: "page-schedule" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$props.i18n.$text('page_title')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_TheDetailPageBody, null, {
      default: _withCtx(() => [
        _createElementVNode("section", null, [
          (_ctx.locale === 'ja')
            ? (_openBlock(), _createElementBlock("p", _hoisted_1, "スケジュールページは後日公開します。"))
            : (_openBlock(), _createElementBlock("p", _hoisted_2, " The Schedule page will be released at a later date. "))
        ])
      ]),
      _: 1
    })
  ], 64))
}