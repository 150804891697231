import { RouteRecordRaw } from 'vue-router';
import Single from '@/views/Single.vue';
import Prologue from '@/components/pages/prologue/Prologue2024.vue';

const PROLOGUE2024_ROUTES: Array<RouteRecordRaw> = [
  {
    path: '/2024-prologue',
    alias: '/en/2024-prologue',
    name: '2024prologueRoot',
    component: Single,
    children: [
      {
        path: '',
        name: '2024-prologue',
        component: Prologue,
        meta: {
          title: 'VR JMoF 2024 Prologue',
          enTitle: 'VR JMoF 2024 Prologue',
        },
      },
    ],
  },
];

export default PROLOGUE2024_ROUTES;
