import { RouteRecordRaw } from 'vue-router';
import General from '@/views/General.vue';
import Access from '@/components/pages/access/Access.vue';
import Instances from '@/components/pages/access/Instances.vue';

const ACCESS_ROUTES: Array<RouteRecordRaw> = [
  {
    path: '/access',
    alias: '/en/access',
    name: 'AccessRoot',
    component: General,
    children: [
      {
        path: '',
        name: 'Access',
        component: Access,
        meta: { title: 'アクセス方法', enTitle: 'How to Access' },
      },
      {
        path: 'instances',
        name: 'Instances',
        component: Instances,
        meta: {
          title: '会場一覧',
          enTitle: 'World List',
        },
      },
    ],
  },
];

export default ACCESS_ROUTES;
