import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheDetailPageHeader = _resolveComponent("TheDetailPageHeader")!
  const _component_ProgramBody = _resolveComponent("ProgramBody")!
  const _component_TheDetailPageBody = _resolveComponent("TheDetailPageBody")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TheDetailPageHeader, { class: "page-program" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$props.i18n.$text('page_title')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_TheDetailPageBody, null, {
      default: _withCtx(() => [
        _createVNode(_component_ProgramBody, { i18n: _ctx.i18n }, null, 8, ["i18n"])
      ]),
      _: 1
    })
  ], 64))
}