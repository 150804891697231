
import { defineComponent } from 'vue';
import PrologueTemplate from '@/components/templates/prologue/Prologue2024Template.vue';
import { pageview } from 'vue-gtag';

export default defineComponent({
  name: 'Prologue',
  methods: {
    track() {
      pageview({ page_path: '/2024-prologue' });
    },
  },
  components: { PrologueTemplate },
});
