
import { defineComponent } from 'vue';
import TheDetailPageHeader from '@/components/organisms/detailPage/TheDetailPageHeader.vue';
import TheDetailPageBody from '@/components/organisms/detailPage/TheDetailPageBody.vue';
// import ScheduleBody from '@/components/organisms/program/ScheduleBody.vue';
import { useI18n } from '@/plugins/i18n';

export default defineComponent({
  name: 'ScheduleTemplate',
  components: {
    TheDetailPageHeader,
    TheDetailPageBody,
    // ScheduleBody,
  },
  props: {
    i18n: {
      type: Object,
    },
  },
  setup() {
    const i18n = useI18n();
    const locale = i18n.locale;
    return {
      locale,
    };
  },
});
