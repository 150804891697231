
import { defineComponent } from 'vue';
import TheDetailPageBody from '@/components/organisms/detailPage/TheDetailPageBody.vue';

export default defineComponent({
  name: 'ProgramTemplate',
  components: {
    TheDetailPageBody,
  },
});
